import RC1Image from "../RC1Image/RC1Image";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "helpers/responsive";
import styles from "./CollectionListHomePage.module.scss";
import Slider from "react-slick";
import { Fragment, useEffect, useState } from "react";
import { Link } from "@material-ui/core";
import Buttons from "../Buttons/Buttons";
import SlickArrows from "../SlickArrows/SlickArrows";

interface CollectionSLideProps {
	showBylide?: "1" | "2" | "3";
	widthImg?: number;
	heightImg?: number;
	cardVariant?: "normal" | "full" | "split" | "center";
	products: any[];
}

const CollectionSLide = ({
	showBylide = "3",
	widthImg = 500,
	heightImg = 500,
	products,
}: CollectionSLideProps): JSX.Element => {
	const [mounted, setMounted] = useState(false);
	const isMobile = useMediaQuery({ query: `(max-width: ${SCREENS.sm})` });

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isMobile ? 1 : parseInt(showBylide),
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: isMobile ? "15%" : "5%",
	};

	useEffect(() => {
		setMounted(true);
		return () => setMounted(false);
	}, []);

	return (
		<>
			{mounted && products && (
				<Slider
					nextArrow={
						<SlickArrows
							type="next"
							light={true}
							onClick={onclick}
							bottom={null}
						/>
					}
					prevArrow={
						<SlickArrows
							type="prev"
							light={true}
							onClick={onclick}
							bottom={null}
						/>
					}
					{...settings}
				>
					{products
						.filter((product) => !product.tags.includes("Hidden"))
						.map((item, index) => (
							<Fragment key={index}>
								<div className={styles.slide} key={index}>
									<div className={styles.simpleCard}>
										<div className={styles.imageContainer}>
											<Link
												href={`/products/${item?.handle}`}
											>
												<RC1Image
													layout="responsive"
													objectFit="contain"
													src={
														item?.media
															? item?.media[0]
																	?.src +
															  "?fm=webp"
															: null ||
															  "/no-image.jpeg"
													}
													width={widthImg}
													height={heightImg}
													alt={item?.title}
													loading="lazy"
													className={styles.img}
												/>
											</Link>
										</div>

										<h2>{item?.title}</h2>
										<div
											className={styles.buttonCollection}
										>
											<Buttons
												text="Shop Now"
												destination={`/products/${item?.handle}`}
												variant="fill"
											/>
										</div>
									</div>
								</div>
							</Fragment>
						))}
				</Slider>
			)}
		</>
	);
};

export default CollectionSLide;
