import { FunctionComponent } from "react";

import styles from "./Banner.module.scss";
import Carousel from "react-bootstrap/Carousel";
import Buttons from "../Buttons/Buttons";
import Badge from "react-bootstrap/Badge";
import useSortDataByDate from "@/hooks/useSortDataByDate";
import Image from "next/image";
import { ISlidingBanner } from "@/interfaces/feacturedBanner";

type SliderProps = {
	slides: ISlidingBanner[];
};

const BannerSlide: FunctionComponent<SliderProps> = ({
	slides,
}: SliderProps) => {
	const [sortByDateSliders] = useSortDataByDate(slides);

	if (!sortByDateSliders) return null;
	return (
		<div className={styles.bannerSlide}>
			<Carousel style={{ height: "100%" }} indicators={false}>
				{sortByDateSliders &&
					sortByDateSliders.map(
						(slide: ISlidingBanner, index: any) => (
							<Carousel.Item
								key={index}
								style={{
									height: "100%",
								}}
							>
								<div
									className={`${styles.slideImage} ${styles.desktop}`}
								>
									<Image
										draggable={false}
										src={
											slide?.fields?.image?.fields?.file
												.url
												? "https:" +
												  slide?.fields?.image?.fields
														?.file.url +
												  "?fm=webp"
												: null || "/no-image.jpeg"
										}
										layout="fill"
										objectFit="cover"
										alt={slide?.fields.altText}
										priority={true}
									/>
								</div>
								<div
									className={`${styles.slideImage} ${styles.mobile}`}
								>
									<Image
										draggable={false}
										src={
											slide?.fields?.mobileImage?.fields
												?.file.url
												? "https:" +
												  slide?.fields?.mobileImage
														?.fields?.file.url +
												  "?fm=webp"
												: null || "/no-image.jpeg"
										}
										layout="fill"
										objectFit="contain"
										alt={slide?.fields?.altText}
										priority={true}
									/>
								</div>
								<Carousel.Caption className={styles.slideText}>
									{slide?.fields?.tags && (
										<>
											{slide?.fields?.tags.map(
												(tag: any, index: any) => (
													<Badge
														style={{
															marginRight: "3px",
														}}
														bg="light"
														text="dark"
														key={index}
													>
														{tag}
													</Badge>
												)
											)}
										</>
									)}
									<h1
										style={{
											color:
												slide?.fields?.textColor != null
													? slide?.fields?.textColor
													: null,
											textShadow:
												slide?.fields?.shadowColor !=
												null
													? "0 0 8px" +
													  slide?.fields?.shadowColor
													: null,
										}}
									>
										{slide?.fields?.label}
									</h1>
									<p
										style={{
											color:
												slide?.fields?.textColor != null
													? slide?.fields?.textColor
													: null,
											textShadow:
												slide?.fields?.shadowColor !=
												null
													? "0 0 8px" +
													  slide?.fields?.shadowColor
													: null,
										}}
									>
										{slide?.fields?.description}
									</p>
									{slide?.fields?.button && (
										<Buttons
											variant="fill"
											text={
												slide?.fields?.button?.fields
													?.label
											}
											destination={
												slide?.fields?.button?.fields
													?.destinationUrl
											}
										/>
									)}
								</Carousel.Caption>
							</Carousel.Item>
						)
					)}
			</Carousel>
		</div>
	);
};

export default BannerSlide;
