import React, { Fragment } from "react";
import styles from "./MainGrid.module.scss";
import MainCard from "components/MainCard/MainCard";
import SmallCard from "components/SmallCard/SmallCard";
import SlideCustomGrid from "../SlideCustomGrid/SlideCustomGrid";

export interface MainGridProps {
	collectionCards: {
		title: string;
		left: any[];
		right: any[];
	};
}

const MainGrid = ({ collectionCards }: MainGridProps): JSX.Element => {
	if (!collectionCards) return null;

	return (
		<>
			<section className={styles.mainGridSlide}>
				<SlideCustomGrid
					collectionCardsRight={collectionCards.right}
					collectionCardsLeft={collectionCards.left}
				/>
			</section>
			<section className={styles.mainGridContainer}>
				<article className={styles.mainGridLeft}>
					{collectionCards.left &&
						collectionCards.left.map((card: any, index) => (
							<Fragment key={index}>
								{card?.fields?.fullSize ? (
									<div className={styles.mainCard}>
										<MainCard card={card?.fields} />
									</div>
								) : (
									<div className={styles.smallCard}>
										<SmallCard card={card?.fields} />
									</div>
								)}
							</Fragment>
						))}
				</article>
				<article className={styles.mainGridRight}>
					{collectionCards.right &&
						collectionCards.right.map((card: any, index: any) => (
							<Fragment key={index}>
								{card?.fields?.fullSize ? (
									<div className={styles.mainCard}>
										<MainCard
											card={card?.fields}
											flexPosition="center"
										/>
									</div>
								) : (
									<div className={styles.smallCard}>
										<SmallCard card={card?.fields} />
									</div>
								)}
							</Fragment>
						))}
				</article>
			</section>
		</>
	);
};

export default MainGrid;
