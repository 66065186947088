import styles from "./SlideCustomGrid.module.scss";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "helpers/responsive";
import Buttons from "../Buttons/Buttons";
import Slider from "react-slick";
import SlickArrows from "../SlickArrows/SlickArrows";

interface SlideCustomProps {
	collectionCardsLeft: any[];
	collectionCardsRight: any[];
	showBylide?: "1" | "3" | "4";
}

const SlideCustomGrid = ({
	collectionCardsRight,
	collectionCardsLeft,
	showBylide = "3",
}: SlideCustomProps) => {
	const items = [...collectionCardsLeft, ...collectionCardsRight];
	const isMobile = useMediaQuery({ query: `(max-width: ${SCREENS.sm})` });
	const settings = {
		infinite: false,
		speed: 500,
		slidesToShow: isMobile ? 1 : parseInt(showBylide),
		slidesToScroll: 1,
	};

	if (!items) return null;

	return (
		<Slider
			nextArrow={
				<SlickArrows
					type="next"
					light={true}
					onClick={() => {
						onclick;
					}}
					bottom={null}
				/>
			}
			prevArrow={
				<SlickArrows
					type="prev"
					light={true}
					onClick={() => {
						onclick;
					}}
					bottom={null}
				/>
			}
			{...settings}
		>
			{items?.map((item: any, index: any) => (
				<div className={styles.slide} key={index}>
					<div
						className={styles.wrapCard}
						style={{
							background: item?.fields?.backgroundColor
								? item?.fields?.backgroundColor
								: "#F9F9F9",
							paddingBottom:
								"https:" +
								item?.fields?.image?.fields?.file?.url
									? "0"
									: "3em",
							height: "100%",
						}}
					>
						<div className={styles.cardHeader}>
							<h2 style={{ color: item?.fields?.fontColor }}>
								{item?.fields?.title}
							</h2>
							<p style={{ color: item?.fields?.fontColor }}>
								{item?.fields?.description}
							</p>
							<Buttons
								text="Shop Now"
								destination={
									item.fields.button.fields.destinationUrl ||
									`/search?q=${item.fields.title}`
								}
								variant="fill"
							/>
						</div>
						<div className={styles.cardImage}>
							<img
								className="img-fluid"
								src={
									"https:" +
									item?.fields?.image?.fields?.file?.url
								}
								alt={
									item?.fields?.altText || item?.fields?.title
								}
							/>
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default SlideCustomGrid;
