import styles from "./LovedSection.module.scss";
import SlideCustomVideo from "../SlideCustomVideo/SlideCustomVideo";

export interface LovedSectionProps {
    videos: any;
}

const LovedSection = ({ videos }: LovedSectionProps): JSX.Element => {
    return (
        <>
            <div className={styles.loveHeader}>
                <h2>{videos?.label}</h2>
            </div>
            <SlideCustomVideo videoList={videos?.videos}
                showBylide="2"
                centerMode={true}
                centerPadding="5%" />
        </>
    );
};

export default LovedSection;
