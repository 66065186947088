import RC1Image from "../RC1Image/RC1Image";
import styles from "./Elite.module.scss";
import Buttons from "../Buttons/Buttons";
import SlideCustomCard from "../SlideCustomCard/SlideCustomCard";
import { IButton } from "interfaces/index";

interface EliteProps {
	data: {
		// eslint-disable-next-line camelcase
		content_type: string;
		description: string;
		label: string;
		title: string;
		button: IButton;
		athletes: {
			fields: {
				links: AthleteProps[];
			};
		};
	};
}
interface AthleteProps {
	// eslint-disable-next-line camelcase
	content_type: string;
	links: Array<any>[];
	listType: string;
	title: string;
}

const Elite = ({ data }: EliteProps): JSX.Element => {
	if (!data) return null;

	return (
		<div className={styles.alite}>
			<div
				className={styles.aliteVector}
				style={{ backgroundImage: `url("/Vector.png")` }}
			>
				<div className={styles.aliteHeader}>
					<h2>{data?.label}</h2>
					<p>{data?.description}</p>
				</div>

				<SlideCustomCard
					items={data?.athletes?.fields["links"]}
					widthImg={400}
					heightImg={500}
					showBylide="3"
					bg="transparent"
				/>
				<div className={styles.featured}>
					<span>Featured on</span>
					<div className={styles.imgAlite}>
						<RC1Image
							src="/espn.png"
							width={183}
							height={68}
							alt="espn"
							loading="lazy"
						/>
					</div>
					<div className={styles.btn1}>
						<Buttons
							destination={
								data?.button?.fields?.destinationUrl || "/"
							}
							text={data?.button?.fields?.label}
							variant="fill"
							color="light"
						/>
					</div>
				</div>
			</div>
			<div className={styles.btn2}>
				<Buttons
					destination={data?.button?.fields?.destinationUrl || "/"}
					text={data?.button?.fields?.label}
					variant="fill"
					color="danger"
				/>
			</div>
		</div>
	);
};

export default Elite;
