import { IProduct } from "interfaces/products.interfaces";
import CardProduct from "../CardProduct/CardProduct";

export interface CardProductListProps {
	productsList: IProduct[];
	col: number;
	extraStyles?: string;
	slice?: number;
}
const CardProductList = ({
	productsList,
	extraStyles = "",
	col,
	slice = 0,
}: CardProductListProps): JSX.Element => {
	return (
		<>
			{productsList && (
				<div className="col-md-12 d-none d-md-block">
					{slice !== 0 ? (
						<div className="row">
							{productsList
								.slice(0, slice)
								.map((product, index) => (
									<div
										key={index}
										className={`col-md-${col} mt-4 ${extraStyles}`}
									>
										<CardProduct
											product={product}
											variant="split"
											buttonText="Shop Now"
										/>
									</div>
								))}
						</div>
					) : (
						<div className="row">
							{productsList.map((product, index) => (
								<div
									key={index}
									className={`col-md-${col} mt-4 ${extraStyles}`}
								>
									<CardProduct
										product={product}
										variant="split"
										buttonText="Shop Now"
									/>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default CardProductList;
