import Image from "next/image";
import { FunctionComponent } from "react";
import styles from "./Banner.module.scss";
interface BannerImageProp {
	image: any;
	alt: string;
}

const BannerImage: FunctionComponent<BannerImageProp> = ({
	image,
	alt,
}: BannerImageProp) => {
	return (
		<div className={styles.bannerImage}>
			<Image
				width={3000}
				height={5015}
				layout={"responsive"}
				src={"https:" + image?.url || null}
				alt={alt}
			/>
		</div>
	);
};

export default BannerImage;
