import CardProductList from "@/components/CardProductList/CardProductList";
import SlideCustomCard from "@/components/SlideCustomCard/SlideCustomCard";

interface ProductListProps {
	products: any[];
}

const ProductList = ({ products }: ProductListProps): JSX.Element => {
	return (
		<section className="container-fluid rc1-container mt-4">
			<div className="col-md-12 mt-5 d-block d-md-none">
				{products && (
					<SlideCustomCard
						items={products}
						cardVariant="split"
						setMargin="1"
						withOutFieldsInData
					/>
				)}
			</div>
			<article className="row">
				{products && (
					<CardProductList
						extraStyles=""
						productsList={products}
						col={4}
						slice={3}
					/>
				)}
			</article>
		</section>
	);
};

export default ProductList;
