import Link from "next/link";
import { Fragment } from "react";
import Buttons from "../Buttons/Buttons";
import styles from "./SubHeader.module.scss";

interface SubHeaderProps {
	vendors: {
		title: string;
		onlineVendors: any[];
		storeVendors: any[];
	};
}

const SubHeader = ({ vendors }: SubHeaderProps): JSX.Element => {
	const renderLinks = (data: any) => {
		return (
			<div>
				{data?.destinationUrl ? (
					<Link prefetch={false} href={data?.destinationUrl || "/"}>
						<a>
							<img
								src={
									data?.image?.fields?.file?.url
										? "https:" +
										  data?.image?.fields?.file?.url +
										  "?fm=webp"
										: null
								}
								alt={data?.altText}
							/>
						</a>
					</Link>
				) : (
					<img
						src={data?.image?.fields?.file?.url + "?fm=webp"}
						alt={data?.title}
					/>
				)}
			</div>
		);
	};

	return (
		<>
			<div className={`container-fluid p-3 ${styles.subHeader}`}>
				<div className="row">
					<div
						className={`${styles.section1} ${styles.icons_wrapper} ${styles.online_store_links}`}
					>
						<div className={`${styles.text} ${styles.icon}`}>
							<p>ONLINE, SHIPPED FAST:</p>
						</div>
						{vendors?.onlineVendors.map(
							(online: any, index: any) => (
								<div
									key={index}
									className={`${styles.icon} ${
										online?.sys?.contentType?.sys?.id ===
										"imageLink"
											? styles.image_link
											: ""
									}`}
								>
									{online?.sys?.contentType?.sys?.id ===
									"imageLink" ? (
										<>{renderLinks(online?.fields)}</>
									) : (
										<Buttons
											variant="fill"
											destination={
												online?.fields?.destinationUrl
													? online?.fields
															?.destinationUrl
													: "/"
											}
											text={online?.fields?.title}
											className={styles.button}
										/>
									)}
								</div>
							)
						)}
					</div>
				</div>
				<div className="row">
					<div
						className={`${styles.section2} ${styles.icons_wrapper}`}
					>
						<div className={`${styles.text} ${styles.icon}`}>
							<p>AVAILABLE IN STORE:</p>
						</div>
						{vendors?.storeVendors.map((store: any, index) => (
							<div key={index} className={`${styles.icon}`}>
								{store?.sys?.contentType?.sys?.id ===
								"imageLink" ? (
									<>{renderLinks(store?.fields)}</>
								) : (
									<Buttons
										destination={
											store?.fields?.destinationUrl
												? store?.fields?.destinationUrl
												: "/"
										}
										text={store?.fields?.title}
										className={styles.button}
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default SubHeader;
