import React from "react";
import styles from "./BarVideo.module.scss";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Script from "next/script";

export interface BarVideoProps {
	withShadom?: boolean;
	data: {
		tags: Array<string>;
		title: string;
		// eslint-disable-next-line camelcase
		content_type: string;
		description: string;
		label: string;
		linkObject: {
			fields: {
				platform: string;
				textSide: string;
				title: string;
				videoId: string;
				sundaySkyIDs: string;
			};
		};
	};
}

const BarVideo = ({
	withShadom = false,
	data = null,
}: BarVideoProps): JSX.Element => {
	const [showModal, setShowModal] = useState(false);

	const handleClose = () => {
		setShowModal(false);
	};

	const openModal = () => {
		setShowModal(true);
	};

	function renderSSkyVid(id: string) {
		return React.createElement("sundaysky-video", {
			id: "sskyplayer",
			"analytics-token": id,
			"endpoint-url":
				"https://apis.sundaysky.com/get-video/player-session/" + id,
			"transcript-button": "show",
		});
	}

	if (!data) return null;
	return (
		<>
			<div className="col-md">
				<div
					className={`${styles.barVideo} ${
						withShadom && styles.shadom
					}`}
				>
					<div className={styles.videWrap}>
						<div
							className={styles.blockBg}
							onClick={openModal}
							onKeyPress={openModal}
							role="button"
							tabIndex={0}
						></div>

						<div className={styles.sskythumb}>
							<img
								src="https://res.cloudinary.com/redcon1/image/upload/v1653405229/Website/dtc-usa/loading/product-loading_urzdg2.png"
								alt="generic thumbnail"
							/>
						</div>
					</div>
					<div className={styles.text}>
						{data?.tags &&
							data?.tags.map((tag: string, index: any) => (
								<Badge bg="danger" key={index}>
									{tag.toUpperCase()}
								</Badge>
							))}

						<h3>{data?.label}</h3>
						<p>{data?.description}</p>
					</div>
				</div>
			</div>
			<Modal
				size="xl"
				dialogClassName={styles.customModal}
				show={showModal}
				onHide={() => handleClose()}
				centered
			>
				<Modal.Header closeButton className={styles.modalHeader}>
					<Modal.Title>{data?.label}</Modal.Title>
				</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					<div className={styles.videoWrapModel}>
						<div
							className={styles.blockBg}
							onClick={openModal}
							onKeyPress={openModal}
							role="button"
							tabIndex={0}
						></div>
						{data?.linkObject?.fields?.videoId ? (
							<>
								{data?.linkObject?.fields?.platform ===
								"youtube" ? (
									<iframe
										src={`https://www.youtube.com/embed/${data?.linkObject?.fields?.videoId}`}
										title="YouTube video player"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen={true}
									></iframe>
								) : (
									data?.linkObject?.fields?.platform ===
										"vimeo" && (
										<iframe
											src={`https://player.vimeo.com/video/${data?.linkObject?.fields?.videoId}`}
											title="Vimeo video player"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen={true}
										></iframe>
									)
								)}
							</>
						) : (
							<>
								{data?.linkObject?.fields?.sundaySkyIDs && (
									<div
										id="sskydiv"
										className={styles.rc1SkyWrapper}
									>
										{renderSSkyVid(
											data?.linkObject?.fields
												?.sundaySkyIDs
										)}
									</div>
								)}
							</>
						)}
					</div>
				</Modal.Body>
			</Modal>

			<Script src="https://play.sundaysky.com/main/sundaysky-player.grey.en-us.min.js" />
		</>
	);
};

export default BarVideo;
