import React, { Fragment } from "react";
import Head from "next/head";
import styles from "styles/home.module.scss";
import Banner from "@/components/Banner/Banner";
import SubHeader from "@/components/SubHeader/SubHeader";
import Elite from "@/components/Elite/Elite";
import LovedSection from "@/components/LovedSection/LovedSection";
import MainGrid from "@/components/MainGrid/MainGrid";
import CollectionListHomePage from "@/components/CollectionListHomePage/CollectionListHomePage";
import BigSection from "@/components/BigSection/BigSection";
import SlideCustomVideo from "@/components/SlideCustomVideo/SlideCustomVideo";

import $nacelle from "services/nacella";
import NotFound from "@/modules/NotFound/NotFound";
import ProductList from "@/modules/HomePage/components/ProductList/ProductList";
import BackToTopBtn from "@/components/BackToTopBtn/BackToTopBtn";

import {
	fetchDataProducts,
	fetchHomePageReference,
} from "utils/fetchDataInObject";

type IndexPageProps = {
	content: any;
	productList: any;
	collectionInterfaceList: any;
	newData: any;
	serverDate: string;
};

const Index = ({ content }: IndexPageProps) => {
	if (content) {
		const homeContent = content?.fields?.content;
		return (
			<>
				<Head>
					<title>
						REDCON1 - Fastest Growing Sports Supplement Brand In
						History
					</title>
					{content?.fields?.seoTitle ? (
						<>
							<meta
								key="title"
								name="title"
								content={content?.fields?.seoTitle}
							/>
						</>
					) : null}
					{content?.fields?.seoDescription ? (
						<>
							<meta
								name="description"
								key="description"
								content={content?.fields?.seoDescription}
							/>
						</>
					) : null}
				</Head>
				{homeContent ? (
					<>
						{homeContent.map((item: any, index: any) => (
							<Fragment key={index}>
								{item.sys.contentType.sys.id ===
									"homepageBanner" && (
									<Banner bannerData={item?.fields} />
								)}
							</Fragment>
						))}
						<section className="container-fluid rc1-container">
							<section className="row">
								<div
									className={`col-md-12 mt-5 ${styles.sub_header}`}
								>
									{homeContent.map(
										(item: any, index: any) => (
											<Fragment key={index}>
												{item.sys.contentType.sys.id ===
													"vendors" && (
													<>
														<SubHeader
															vendors={
																item?.fields
															}
														/>
													</>
												)}
											</Fragment>
										)
									)}
								</div>
							</section>
							<section className="container">
								<article className="row">
									<div className="col">
										<h2 className={styles.title}>
											MUST HAVE REDCON1 BEST-SELLERS
										</h2>
									</div>
								</article>
							</section>
							{homeContent.map((item: any, index: any) => (
								<Fragment key={index}>
									{item.sys.contentType.sys.id ===
										"collection" && (
										<>
											<ProductList
												products={
													item?.fields?.products
												}
											/>
										</>
									)}
								</Fragment>
							))}
						</section>
						<section>
							<article className={styles.collectionCardList}>
								{homeContent.map((item: any, index: any) => (
									<Fragment key={index}>
										{item.sys.contentType.sys.id ===
											"collectionCardList" && (
											<>
												<MainGrid
													collectionCards={
														item?.fields
													}
												/>
											</>
										)}
									</Fragment>
								))}
							</article>
						</section>
						{homeContent &&
							homeContent.map((item: any, index: any) => (
								<Fragment key={index}>
									{item.sys.contentType.sys.id ===
										"subBanner" && (
										<>
											<BigSection item={item} />
										</>
									)}
								</Fragment>
							))}
						<section
							className={`container-fluid my-4 ${styles.spaceCutomContainer}`}
						>
							<article className="col-md-12 pt-5 pb-2">
								<div
									className={`row ${styles.colNotMargin}`}
								></div>
								{homeContent &&
									homeContent.map((item: any, index: any) => (
										<Fragment key={index}>
											{item.sys.contentType.sys.id ===
												"videoList" &&
												item?.fields?.section === 1 && (
													<SlideCustomVideo
														videoList={
															item?.fields?.videos
														}
														showHeader={true}
														showBylide="2"
														centerMode={true}
														centerPadding="5%"
													/>
												)}
										</Fragment>
									))}
							</article>
						</section>
						{homeContent &&
							homeContent.map((item: any, index: any) => (
								<Fragment key={index}>
									{item.sys.contentType.sys.id ===
										"collectionInterfaceList" && (
										<CollectionListHomePage
											collectionList={item?.fields}
										/>
									)}
								</Fragment>
							))}

						{homeContent &&
							homeContent.map((item: any, index: any) => (
								<section
									key={index}
									className="container-fluid mt-4"
								>
									{item.sys.contentType.sys.id ===
										"athleteGallery" && (
										<article className="row">
											<div className="col-md-12 px-0">
												<Elite data={item?.fields} />
											</div>
										</article>
									)}
								</section>
							))}
						{homeContent &&
							homeContent.map((item: any, index: any) => (
								<section
									key={index}
									className="container-fluid mt-5"
								>
									{item.sys.contentType.sys.id ===
										"videoList" &&
										item?.fields?.section === 2 && (
											<article className="row">
												<div className="col-md-12 px-0">
													<LovedSection
														videos={item?.fields}
													/>
												</div>
											</article>
										)}
								</section>
							))}

						<BackToTopBtn />
					</>
				) : (
					<div>
						<p>loading....</p>
					</div>
				)}
			</>
		);
	} else {
		return <NotFound />;
	}
};

export async function getStaticProps() {
	let content: any = {};

	try {
		content = await $nacelle.data.content({
			handle: "homepage_us_retail",
			type: "storePage",
		});
		if (content) {
			await fetchHomePageReference(content?.fields?.content);
			await fetchDataProducts(content?.fields?.content);
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log("page was not found");
	}

	return {
		props: {
			content,
		},
		revalidate: parseInt(process.env.ISR_TIMEOUT),
	};
}

export default Index;
