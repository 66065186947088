import Buttons from "../Buttons/Buttons";
import styles from "./MainCard.module.scss";

export interface MainCardProps {
	card: any;
	flexPosition?: "center" | "flex-start" | "flex-end";
}

const MainCard = ({
	card,
	flexPosition = "center",
}: MainCardProps): JSX.Element => {
	return (
		<div
			style={{
				background: card?.backgroundColor
					? card?.backgroundColor
					: "#F9F9F9",
				paddingBottom:
					"https:" + card?.image?.fields?.file.url ? "0" : "3em",
			}}
			className={styles.mainCard}
		>
			<div className={styles.mainCardHedaer}>
				<h2 style={{ color: card?.fontColor }}>{card?.title}</h2>
				<p style={{ color: card?.fontColor }}>{card?.description}</p>
				<Buttons
					text={card?.button?.fields?.label || "Text button"}
					destination={card?.button?.fields?.destinationUrl || "/"}
					variant="fill"
					contentType={card.button.content_type}
					svg={card.button?.fields.image?.fields?.file.url}
				/>
			</div>
			{card?.image?.fields?.file.url && (
				<div
					className={styles.cardImage}
					style={{ justifyContent: flexPosition }}
				>
					<img
						className={styles.image}
						src={
							card?.image?.fields?.file.url
								? "https:" +
								  card?.image?.fields?.file.url +
								  "?fm=webp"
								: null
						}
						alt={card?.altText}
					/>
				</div>
			)}
		</div>
	);
};

export default MainCard;
