import styles from "./SlideCustomCard.module.scss";
import RC1Image from "../RC1Image/RC1Image";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "helpers/responsive";
import CardProduct from "../CardProduct/CardProduct";
import CardProductCenter from "../CardProductCenter/CardProductCenter";
import Slider from "react-slick";
import { Fragment, useEffect, useState } from "react";
import SlickArrows from "../SlickArrows/SlickArrows";

interface SlideCustomProps {
	items: any[];
	showBylide?: "1" | "2" | "3";
	widthImg?: number;
	heightImg?: number;
	cardVariant?: "normal" | "full" | "split" | "center";
	setMargin?: "0" | "1" | "1.5" | "2" | "2.5" | "3" | "4";
	withOutFieldsInData?: boolean;
	bg?: "white" | "transparent";
}

const SlideCustomCard = ({
	items,
	showBylide = "3",
	widthImg = 500,
	heightImg = 500,
	cardVariant = "normal",
	setMargin = "0",
	withOutFieldsInData = false,
	bg = "white",
}: SlideCustomProps) => {
	const [mounted, setMounted] = useState(false);
	const isMobile = useMediaQuery({ query: `(max-width: ${SCREENS.md})` });
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isMobile ? 1 : parseInt(showBylide),
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: isMobile ? "15%" : "5%",
	};

	useEffect(() => {
		setMounted(true);
	}, []);

	if (!items) return null;

	return (
		<>
			{mounted && (
				<>
					{withOutFieldsInData ? (
						<Slider
							nextArrow={
								<SlickArrows
									type="next"
									light={true}
									onClick={onclick}
									bottom={null}
								/>
							}
							prevArrow={
								<SlickArrows
									type="prev"
									light={true}
									onClick={onclick}
									bottom={null}
								/>
							}
							{...settings}
						>
							{items?.map((item, index) => (
								<Fragment key={index}>
									<div className={styles.slide}>
										{cardVariant === "normal" && (
											<div
												className={styles.simpleCard}
												style={{ backgroundColor: bg }}
											>
												<div
													className={
														styles.imageContainer
													}
												>
													<RC1Image
														layout="responsive"
														objectFit="contain"
														src={
															item?.featuredMedia
																?.src
																? item
																		?.featuredMedia
																		?.src +
																  "?fm=webp"
																: "/no-image.jpeg"
														}
														width={widthImg}
														height={heightImg}
														alt={
															item?.altText ||
															item?.title
														}
														loading="lazy"
														className={styles.img}
													/>
												</div>
											</div>
										)}
										{cardVariant === "full" && (
											<CardProduct
												product={item}
												variant={cardVariant}
												setMargin={setMargin}
												buttonText="Learn More"
												bg={bg}
											/>
										)}
										{cardVariant === "split" && (
											<CardProduct
												product={item}
												variant={cardVariant}
												setMargin={setMargin}
												buttonText="Learn More"
												bg={bg}
											/>
										)}
										{cardVariant === "center" && (
											<CardProductCenter
												product={item}
												setMargin={setMargin}
												buttonText="Learn More"
												bg={bg}
											/>
										)}
										<div className={styles.atheleteName}>
											{item?.fields?.label}
										</div>
									</div>
								</Fragment>
							))}
						</Slider>
					) : (
						<Slider
							nextArrow={
								<SlickArrows
									type="next"
									light={true}
									onClick={onclick}
									bottom={null}
								/>
							}
							prevArrow={
								<SlickArrows
									type="prev"
									light={true}
									onClick={onclick}
									bottom={null}
								/>
							}
							{...settings}
						>
							{items?.map((item, index) => (
								<div className={styles.slide} key={index}>
									{cardVariant === "normal" && (
										<div
											className={styles.simpleCard}
											style={{ backgroundColor: bg }}
										>
											<div
												className={
													styles.imageContainer
												}
											>
												<RC1Image
													layout="responsive"
													objectFit="contain"
													src={
														item?.fields?.image
															?.fields?.file.url
															? "https:" +
															  item?.fields
																	?.image
																	?.fields
																	?.file.url +
															  "?fm=webp"
															: "/no-image.jpeg"
													}
													width={widthImg}
													height={heightImg}
													alt={
														item?.fields?.altText ||
														item?.fields?.title
													}
													loading="lazy"
													className={styles.img}
												/>
											</div>
										</div>
									)}
									{cardVariant === "full" && (
										<CardProduct
											product={item?.fields}
											variant={cardVariant}
											setMargin={setMargin}
											buttonText="Learn More"
										/>
									)}
									{cardVariant === "split" && (
										<CardProduct
											product={item?.fields}
											variant={cardVariant}
											setMargin={setMargin}
											buttonText="Learn More"
										/>
									)}
									{cardVariant === "center" && (
										<CardProductCenter
											product={item?.fields}
											setMargin={setMargin}
											buttonText="Learn More"
										/>
									)}
									<div className={styles.atheleteName}>
										{item?.fields?.label}
									</div>
								</div>
							))}
						</Slider>
					)}
				</>
			)}
		</>
	);
};

export default SlideCustomCard;
