import { convertToRgba } from "@/helpers/convertToRgba";
import { IImage, IButton } from "interfaces/index";
import Buttons from "../Buttons/Buttons";
import styles from "./BigSection.module.scss";

interface BigSectionProps {
	item: {
		fields: {
			button1: IButton;
			button2: IButton;
			image: IImage;
			description: string;
			// eslint-disable-next-line camelcase
			content_type: string;
			innerGradientColor: string;
			outerGradientColor: string;
			title: string;
			label: string;
			altText?: string;
		};
	};
}

const BigSection = ({ item }: BigSectionProps): JSX.Element => {
	return (
		<div
			style={{
				background: `radial-gradient(
		circle,
		${convertToRgba(String(item?.fields?.innerGradientColor))} 7%,
		${convertToRgba(String(item?.fields?.outerGradientColor))} 53%
	)`,
			}}
		>
			<section className={`container ${styles.bigSection}`}>
				<article className="row mt-3 h-100">
					<div className={`col-md mt-4 ${styles.bigSectionImg}`}>
						<img
							src={
								item?.fields?.image?.fields?.file.url
									? item?.fields?.image?.fields?.file.url +
									  "?fm=webp"
									: null
							}
							alt={item?.fields?.altText}
						/>
					</div>
					<div className={`col-md mt-4 ${styles.bigSectionText}`}>
						<h2>{item?.fields?.label}</h2>
						<p>{item?.fields?.description}</p>
						<div className={styles.bigSectionBtn}>
							{item?.fields?.button1 && (
								<Buttons
									contentType={
										item?.fields?.button1?.fields
											?.content_type
									}
									text={item?.fields?.button1?.fields?.label}
									destination={
										item?.fields?.button1?.fields
											?.destinationUrl
									}
									svg={
										item?.fields?.button1?.fields?.image
											?.fields?.file?.url
									}
									variant="fill"
									color="light"
								/>
							)}
							{item?.fields?.button2 && (
								<Buttons
									contentType={
										item?.fields?.button2?.fields
											?.content_type
									}
									text={item?.fields?.button2?.fields?.label}
									destination={
										item?.fields?.button2?.fields
											?.destinationUrl
									}
									svg={
										item?.fields?.button2?.fields?.image
											?.fields?.file.url
									}
									variant="outline"
									color="light"
								/>
							)}
						</div>
					</div>
				</article>
			</section>
		</div>
	);
};

export default BigSection;
