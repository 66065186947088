import { useState, useMemo, useContext } from "react";
import Link from "next/link";
import RC1Image from "../RC1Image/RC1Image";
import styles from "./CardProduct.module.scss";

import Buttons from "../Buttons/Buttons";
import minPrice from "@/helpers/minPrice";
import { currencyFormat } from "utils/currencyFormat";
import RenderSelect from "@/components/RenderSelect/RenderSelect";
import setSwatch from "utils/setSwatch";
import { useEffect } from "react";
import { IProduct } from "interfaces/products.interfaces";
import { UiContext } from "context/ui/UiContext";
import { CartContext } from "context/cart";
interface CardProductProps {
	product: IProduct;
	fetchDataUseApi?: boolean;
	AllowedAddToCart?: boolean;
	variant?: "full" | "split";
	buttonText?: string;
	setMargin?: "0" | "1" | "1.5" | "2" | "2.5" | "3" | "4";
	bg?: "white" | "transparent";
}

const CardProduct = ({
	product,
	AllowedAddToCart = false,
	variant = "split",
	setMargin = "0",
	buttonText = "",
	bg = "white",
}: CardProductProps): JSX.Element => {
	const { cart, addToCart, updateCartList } = useContext(CartContext);
	const [selectVariant, setSelectVariant] = useState<any>(null);
	const { swatches } = useContext(UiContext);
	const [selectSwatch, setSelectSwatch] = useState(null);

	function flavors(variants: any, tags: any) {
		if (tags === undefined) {
			return;
		}

		if (tags.includes("Supplement")) {
			return (
				<p className={styles.variantNum}>
					Available in <span>{variants}</span> delicious flavors!
				</p>
			);
		}
	}
	useEffect(() => {
		if (selectVariant && swatches) {
			const swatchUrl = setSwatch(selectVariant, swatches);
			setSelectSwatch(swatchUrl);
		}
	}, [swatches, selectVariant]);

	useMemo(() => {
		if (product) {
			if (product?.variants.length > 1) {
				setSelectVariant(minPrice(product.variants));
			} else {
				setSelectVariant(product.variants[0]);
			}
		}
	}, [product]);

	const onClick = () => {
		const ramdomNumber = () => Math.floor(Math.random() * Date.now());
		let foundIndex = cart.findIndex((x) => x.id == selectVariant?.id);

		const productPicked: any = {
			title: `${product.title} - ${selectVariant.title}`,
			label: selectVariant.label || null,
			imageUrl:
				selectVariant.featuredMedia?.src ||
				product.featuredMedia?.src ||
				"/no-image.jpeg",
			id: selectVariant.id,
			sku: selectVariant.sku,
			originalPrice: selectVariant.price,
			price: parseFloat(selectVariant.price),
			quantity: cart[foundIndex]?.quantity
				? cart[foundIndex]?.quantity + 1
				: 1,
			handle: product.handle,
			cartItemId: ramdomNumber().toString(),
			productTagRestriction: product?.productTagRestriction,
		};

		if (foundIndex !== -1) {
			updateCartList(productPicked);
		} else {
			addToCart(productPicked);
		}
	};

	return (
		<>
			{product && !product?.tags.includes("Hidden") && (
				<>
					<div
						className={styles.cardProduct}
						style={{ margin: `${setMargin}em`, background: bg }}
					>
						<div className={styles.containerImg}>
							<Link
								prefetch={false}
								href={`/products/${product.handle}`}
							>
								<a>
									<RC1Image
										draggable={false}
										src={
											product?.featuredMedia?.src ||
											"/no-image.jpeg"
										}
										layout="fill"
										objectFit="contain"
										alt={product.title}
									/>
								</a>
							</Link>
						</div>
						{variant === "full" && (
							<>
								<div className={styles.cardMeta}>
									<Link
										prefetch={false}
										href={`/products/${product.handle}`}
									>
										<a>
											<h2>{product.title}</h2>
										</a>
									</Link>
									{product.productDetail?.tagline && (
										<p>{product.productDetail?.tagline}</p>
									)}
								</div>
								<div className={styles.actionCard}>
									<div>
										{product.variants[0]
											?.compareAtPrice && (
											<div
												className={
													styles.compareAtPrice
												}
											>
												{currencyFormat(
													parseFloat(
														product.variants[0]
															?.compareAtPrice
													)
												)}
											</div>
										)}
										<h2>
											{parseFloat(
												minPrice(product.variants)
													?.price
											).toLocaleString("en-US", {
												style: "currency",
												currency: "USD",
											})}
										</h2>
									</div>

									<Buttons
										variant="outline"
										text={
											buttonText ? buttonText : "Buy Now"
										}
										destination={`/products/${product.handle}`}
										color="danger"
									/>
								</div>
							</>
						)}
						{AllowedAddToCart && (
							<RenderSelect
								selectId="CardProductSelect"
								product={product}
								setSelectVariant={setSelectVariant}
								selectVariant={selectVariant}
								selectSwatchUrl={selectSwatch || null}
							/>
						)}
					</div>
					{variant === "split" && (
						<>
							<div className={styles.cardMeta}>
								<Link
									prefetch={false}
									href={`/products/${product.handle}`}
								>
									<a>
										<h2>{product.title}</h2>
									</a>
								</Link>
								{flavors(
									product?.variants?.length,
									product.productDetail?.featuredTags
								)}
								<p>{product.productDetail?.tagline}</p>
							</div>
							<div className={styles.actionCard}>
								<h2>
									{parseFloat(
										minPrice(product.variants)?.price
									).toLocaleString("en-US", {
										style: "currency",
										currency: "USD",
									})}
								</h2>
								{AllowedAddToCart ? (
									<Buttons
										variant="outline"
										text={"Add Cart"}
										callBack={onClick}
										color="danger"
										disabled={
											!selectVariant ||
											!selectVariant.availableForSale
										}
									/>
								) : (
									<Buttons
										variant="outline"
										text={
											buttonText
												? buttonText
												: "Learn More"
										}
										destination={`/products/${product?.handle}`}
										color="danger"
									/>
								)}
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default CardProduct;
