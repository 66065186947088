import { FunctionComponent, Fragment } from "react";
import RC1Image from "../RC1Image/RC1Image";
import Buttons from "../Buttons/Buttons";
import styles from "./Banner.module.scss";
import BannerImage from "./BannerImage";
import BannerSlide from "./BannerSlide";
import { IFeaturedBanner } from "@/interfaces/feacturedBanner";

type BannerProps = {
	bannerData: IFeaturedBanner;
};

const Banner: FunctionComponent<BannerProps> = ({
	bannerData,
}: BannerProps) => {
	if (!bannerData) return null;

	const { featuredBanner, slidingBanners } = bannerData;
	return (
		<>
			<div className={styles.bannerWrap}>
				<section className={styles.banner}>
					<div className={styles.separator}></div>
					<div className={styles.separator}></div>
					<article>
						<div className={styles.textBox}>
							<h2>{featuredBanner?.fields?.label}</h2>
							<p>{featuredBanner?.fields?.description}</p>
						</div>
					</article>
					<article>
						<div className={styles.textBox2}>
							<span>Battle of the Brands Winner</span>
							<RC1Image
								src="https://cdn.shopify.com/s/files/1/1304/0433/files/VS_Vert_RGB_52f3e9f2-f93d-4586-9f33-204d79c93919.svg?v=1661543596"
								width={130}
								height={80}
								layout="fixed"
								objectFit="contain"
								alt="vendor"
							/>
						</div>
					</article>
					<article>
						<div className={styles.buttonBox}>
							{featuredBanner?.fields?.button1 && (
								<Buttons
									destination={
										featuredBanner?.fields?.button1?.fields
											?.destinationUrl || "/"
									}
									variant="fill"
									text={
										featuredBanner?.fields?.button1?.fields
											?.title
									}
								/>
							)}

							{featuredBanner?.fields?.button2 && (
								<Buttons
									destination={
										featuredBanner?.fields?.button2?.fields
											?.destinationUrl || "/"
									}
									variant="fill"
									text={
										bannerData?.featuredBanner?.fields
											?.button2?.fields?.title
									}
								/>
							)}
						</div>
					</article>
					<BannerImage
						image={featuredBanner?.fields?.image?.fields?.file}
						alt={featuredBanner?.fields?.altText}
					/>
					<BannerSlide slides={slidingBanners} />
				</section>
			</div>
		</>
	);
};

export default Banner;
