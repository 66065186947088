import Buttons from "../Buttons/Buttons";
import styles from "./SmallCard.module.scss";
import RC1Image from "../RC1Image/RC1Image";
export interface SmallCardProps {
	card: any;
}

const SmallCard = ({ card }: SmallCardProps): JSX.Element => {
	return (
		<div
			style={{
				background: card?.backgroundColor
					? card?.backgroundColor
					: "#F9F9F9",
			}}
			className={`${styles.smallCard} ${styles[card.class]}`}
		>
			<div className={styles.smallCardMeta}>
				<h2>{card?.title}</h2>
				<p>{card?.description}</p>
				<Buttons
					text={card.button?.fields?.label || "Text button"}
					destination={card.button?.fields?.destinationUrl || "/"}
					contentType={card.button?.fields?.content_type}
					svg={card.button?.fields?.image?.url}
					variant="fill"
				/>
			</div>
			{card?.image?.fields?.file.url && (
				<div className={`${styles.cardImage}`}>
					<RC1Image
						src={
							card?.image?.fields?.file.url
								? "https:" +
								  card?.image?.fields?.file.url +
								  "?fm=webp"
								: null
						}
						className={styles.image}
						layout="fill"
						objectFit="contain"
						alt={card?.altText}
					/>
				</div>
			)}
		</div>
	);
};

export default SmallCard;
