import styles from "./CollectionListHomePage.module.scss";
import Accordion from "react-bootstrap/Accordion";
import BarSection from "@/components/BarSection/BarSection";
import BarVideo from "@/components/BarVideo/BarVideo";
import { Fragment } from "react";
import CollectionSLide from "./SlideCollections";

interface CollectionAccordionItemProps {
	item: any;
	products: any;
	index: any;
}

const CollectionAccordionItem = ({
	item,
	products,
	index,
}: CollectionAccordionItemProps) => {
	return (
		<>
			<Accordion.Item eventKey={index.toString()} key={index}>
				<Accordion.Header>
					<div>
						<span>{item?.label}</span>
					</div>
				</Accordion.Header>
				<Accordion.Body className={styles.bodyAcordion}>
					{products && <CollectionSLide products={products} />}
					<section className="container">
						<div className="row">
							{item?.cardLinks &&
								item?.cardLinks.map((card: any, index: any) => (
									<Fragment key={index}>
										{card?.sys?.contentType?.sys?.id ===
										"largeCardLink" ? (
											<div className="pb-2">
												<BarSection
													cardLink={card?.fields}
												/>
											</div>
										) : (
											<BarVideo data={card?.fields} />
										)}
									</Fragment>
								))}
						</div>
					</section>
				</Accordion.Body>
			</Accordion.Item>
		</>
	);
};

export default CollectionAccordionItem;
