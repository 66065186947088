import { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";

import styles from "./CollectionListHomePage.module.scss";
import CollectionAccordionItem from "./CollectionAccordionItem";

interface CollectionListHomePageProps {
	collectionList: any;
}

const CollectionListHomePage = ({
	collectionList,
}: CollectionListHomePageProps) => {
	const { collectionInterfaces } = collectionList;

	if (!collectionList) return null;
	return (
		<>
			<section
				className={`container-fluid mt-2 ${styles.spaceCutomContainer}`}
			>
				<article className={`row ${styles.colNotMargin}`}>
					<div className={`col-md-12 py-4 ${styles.colNotPadding}`}>
						<Accordion
							className="customAcordion"
							flush={true}
							defaultActiveKey="0"
						>
							{collectionInterfaces &&
								collectionInterfaces.map(({ fields }, index: any) => (
									<Fragment key={index}>
										<CollectionAccordionItem
											item={fields}
											products={fields?.collection?.fields?.products}
											index={index}
										/>
									</Fragment>
								))}
						</Accordion>
					</div>
				</article>
			</section>
		</>
	);
};

export default CollectionListHomePage;
